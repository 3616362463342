<template>
  <div>
    <div v-if="$auth.user[`https://api.pharmaguide.ca/claims/app_metadata`].role === 'superadmin'">
      <router-view></router-view>
      <div v-if="$route.path === '/home'">
        <v-col
          cols="12"
          md="12"
        >
          <pharmacy-list></pharmacy-list>
        </v-col>
      </div>
    </div>
    <div v-else>
      <un-authorized></un-authorized>
    </div>
  </div>
</template>

<script>
import unAuthorized from '@/components/dialog/unAuthorized.vue'

export default {
    name: 'PhoxIqHome',
    components: {
        pharmacyList: () => import('@/components/tables/pharmacyList.vue'),
        unAuthorized,

    },
    setup() {},

}
</script>
